import Vue from 'vue';
import Vuex from 'vuex';
import i18n from '../i18n';

import AuthModule from "./auth.module";
import UserModule from './user.module.js';
import ItemModule from './item.module.js';
import ContractModule from "./contract.module";
import { loader } from "./loading.module";
import OrderModule from "@/store/order.module";
import StorageModule from "@/store/storage.module";
import ReservationModule from "@/store/reservation.module";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        user: UserModule,
        contract: ContractModule,
        auth: AuthModule,
        item: ItemModule,
        reservation: ReservationModule,
        storage: StorageModule,
        order: OrderModule,
        loader
    },
    state: {
        layout: 'app',
        is_show_sidebar: true,
        is_show_search: false,
        is_dark_mode: false,
        dark_mode: 'light',
        locale: null,
        menu_style: 'vertical',
        layout_style: 'full',
        countryList: [
            { code: 'sk', name: 'SK SKLAD' },
            { code: 'cz', name: 'CZ SKLAD' },
            { code: 'hu', name: 'HU RAKTÁR' },
            { code: 'pl', name: 'PL MAGAZYN' },
            { code: 'global', name: 'GLOBAL' }
            // { code: 'en', name: 'EN STORE' }
        ],
        errors: [],
        urlLocale: window.location.hostname.split('.')[0] || 'sk' // Nastaviť jazyk z URL
    },
    mutations: {
        setErrors(state, errors) {
            state.errors = errors;
        },
        setLayout(state, payload) {
            state.layout = payload;
        },
        toggleSideBar(state, value) {
            state.is_show_sidebar = value;
        },
        toggleSearch(state, value) {
            state.is_show_search = value;
        },
        toggleLocale(state, value) {
            value = value || 'en';
            i18n.locale = value;
            localStorage.setItem('i18n_locale', value);
            state.locale = value;
            state.urlLocale = value; // Nastaviť jazyk z URL
        },

        toggleDarkMode(state, value) {
            //light|dark|system
            value = value || 'light';
            localStorage.setItem('dark_mode', value);
            state.dark_mode = value;
            if (value == 'light') {
                state.is_dark_mode = false;
            } else if (value == 'dark') {
                state.is_dark_mode = true;
            } else if (value == 'system') {
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    state.is_dark_mode = true;
                } else {
                    state.is_dark_mode = false;
                }
            }

            if (state.is_dark_mode) {
                document.querySelector('body').classList.add('dark');
            } else {
                document.querySelector('body').classList.remove('dark');
            }
        },

        toggleMenuStyle(state, value) {
            //horizontal|vertical|collapsible-vertical
            value = value || '';
            localStorage.setItem('menu_style', value);
            state.menu_style = value;
            if (!value || value === 'vertical') {
                state.is_show_sidebar = true;
            } else if (value === 'collapsible-vertical') {
                state.is_show_sidebar = false;
            }
        },

        toggleLayoutStyle(state, value) {
            //boxed-layout|large-boxed-layout|full
            value = value || '';
            localStorage.setItem('layout_style', value);
            state.layout_style = value;
        },
    },
    getters: {
        layout(state) {
            return state.layout;
        },
        errors: state => state.errors
    },
    actions: {
    },
});
